.app-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-form {
  display: flex;
  flex-direction: column;
}

.copy-form > h3 {
  text-align: center;
}

.copy-form-link-input {
  padding: 0.5em;
  text-align: center;
  background: transparent;
  border: 2px solid var(--accent-dim);
  margin: 2px;
  font-size: inherit;
}

.copy-form-link-input:focus {
  outline: none;
  margin: 0px;
  border: 4px solid var(--accent);
}

.copy-form-checkbox-label > input {
  margin: 0 5px 0 0;
  width: 2vw;
  height: 2vh;
  min-width: 15px;
  min-height: 15px;
}

.copy-form-submit {
  margin-top: 30px;
}
