#background-div {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(120deg, var(--accent-subtle), transparent);
}

.main-container {
  position: relative;
  z-index: 0;
  display: grid;
  grid-template-areas:
    "nav nav nav"
    ". content .";
  grid-template-columns: 15vw 1fr 15vw;
  grid-template-rows: auto 1fr;
  grid-gap: 10px;
  height: 100vh;
}

.children-container {
  grid-area: content;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .main-container {
    grid-template-areas:
      "nav"
      "content";

    grid-template-columns: 1fr;
    grid-template-rows:
      auto
      1fr;
  }

  .children-container {
    width: 95vw;
  }
}
